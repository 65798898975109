<template></template>

<script>
/**
 * 地图绘制圆形
 */
import { isNil, throttle } from 'lodash';
import { sleepIf } from '@/utils/sleep';
import { loadPlugin } from './utils/loadPlugin';

export default {
  name: 'ACircle',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
    // function 地图根组件
    '_getMapRoot',
  ],
  emits: [
    // 点击事件
    'click',
    'show',
    // 隐藏事件
    'hide',
    'editMoving',
    'editAdjust',
    'editEnd'
  ],
  model: {
    prop: 'isVisible',
  },
  props: {
    // 是否显示
    isVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * PolygonOptions
     */
    ctorOpts: {
      type: Object,
      default: null,
    },
    /**
     * 是否可编辑
     */
    editable: {
      type: Boolean,
      default: false,
    },
    /**
     * 开启编辑状态
     */
    editIsOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    autoFitView: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否自动偏移中心点, 与`autoFitView`互斥, 比`autoFitView`的优先级低
     */
    autoPanTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 在视野内是否平移
     */
    panToInView: {
      type: Boolean,
      default: false,
    },
    /**
     * 圆心位置
     * 错误使用: 使用字面量 `center="[lng,lat]"`
     */
    center: {
      // LngLat
      type: Array,
      default: null,
      required: true,
    },
    /**
     * 圆半径，单位:米
     */
    radius: {
      type: Number,
      default: 1000,
    },
    /**
     * 线条颜色，使用16进制颜色代码赋值
     */
    strokeColor: {
      type: String,
      default: '#FF33FF', // '#006600'
    },
    /**
     * 线条宽度，单位：像素
     */
    strokeWeight: {
      type: Number,
      default: 6,
    },
    /**
     * 线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
     */
    strokeOpacity: {
      type: Number,
      default: 0.2,
    },
    /**
     * 线样式，实线:solid，虚线:dashed
     */
    strokeStyle: {
      type: String,
      default: 'solid',
    },
    /**
     * 多边形填充颜色，使用16进制颜色代码赋值
     */
    fillColor: {
      type: String,
      default: '#1791f'
    },
    /**
     * 多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。
     */
    fillOpacity: {
      type: Number,
      default: 0.4,
    }

  },
  data() {
    // 覆盖物 矩形实例
    this.mapOverlay = null;
    // 圆形编辑插件 实例
    this.mapEditor = null;
    return {};
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.show();
      } else {
        this.hide();
      }
    },
    async editable(val) {
      const { mapEditor } = this;
      if (val) {
        mapEditor || this.initEditor();
      }
    },
    async editIsOpen(isOpen) {
      const { editable } = this;
      if (isOpen) {
        if (editable) {
          // 等圆画好
          await this.$nextTick();
          this.openEditor();
        }
      } else {
        this.closeEditor();
      }
    },
    center(val) {
      this.setCenter(val);
    },
    radius(val) {
      this.setRadius(val);
    },
  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEditMoving = this.onEditMoving.bind(this);
    this.onEditAdjust = this.onEditAdjust.bind(this);
    this.onEditEnd = this.onEditEnd.bind(this);

    this.fitViewOrPanTo = throttle(this.fitViewOrPanTo, 500).bind(this);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { mapOverlay, mapEditor } = this;

    if (mapEditor) {
      mapEditor.off('move', this.onEditMoving);
      mapEditor.off('adjust', this.onEditAdjust);
      mapEditor.off('end', this.onEditEnd);
    }

    if (mapOverlay) {
      mapOverlay.off('click', this.onClick);
      mapOverlay.off('hide', this.onHide);
      mapOverlay.off('show', this.onShow);
    }
    this.clearEditor();
    this.clear();
  },
  methods: {

    /**
     * 初始化
     */
    async init() {
      const { editable } = this;
      await this.initCircle();

      if (editable) {
        await this.initEditor();
      }
    },

    /**
     * 平移到可视区或中心
     */
    fitViewOrPanTo() {
      const { autoFitView, autoPanTo, panToInView } = this;
      if (autoFitView) {
        this.setFitView();
      } else if (autoPanTo) {
        // 平移到中心点
        this.panToCenter(panToInView);
      }
    },
    /**
     * 地图中心点平移至指定点位置
     * `inView`: false, 在视野外才会平移
     */
    panToCenter(inView = false) {
      const map = this.getMap();
      if (!map) return;
      const { center } = this;
      if (!center) return;

      const bounds = map.getBounds();
      if (inView || !bounds.contains(center)) {
        map.panTo(center);
      }
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      const map = this.getMap();
      map?.setFitView(mapOverlay);
    },

    /**
     * 显示
     */
    show() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.show();
      this.fitViewOrPanTo();
    },
    /**
     * 隐藏
     */
    hide() {
      const { mapOverlay } = this;
      mapOverlay?.hide();
    },
    /**
     * 获取圆中心点
     * @property {LngLat}
     */
    getCenter() {
      const { mapOverlay } = this;
      return mapOverlay?.getCenter();
    },
    /**
     * 设置圆中心点
     */
    setCenter(lngLat) {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.setCenter(lngLat);
      this.fitViewOrPanTo();
    },
    /**
     * 获取圆形的半径
     */
    getRadius() {
      const { mapOverlay } = this;
      return mapOverlay?.getRadius();
    },
    /**
     * 设置圆形的半径
     * @param {number} radius
     */
    setRadius(radius) {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.setRadius(radius);
      this.fitViewOrPanTo();
    },
    /**
     * 判断指定点坐标是否在圆内
     */
    contains(lngLat) {
      const { mapOverlay } = this;
      return mapOverlay?.contains(lngLat);
    },

    async initCircle() {

      // 等待地图实例化完成
      await sleepIf(8000, () => !isNil(this.getMap()));

      const {
        center,
        radius,
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        ctorOpts,
        isVisible
      } = this;
      const map = this.getMap();

      // https://lbs.amap.com/api/javascript-api/reference/overlay#circle
      const circle = new AMap.Circle({
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        map,

        ...(ctorOpts || {})
      });
      this.mapOverlay = circle;

      this.setCenter(center);
      this.setRadius(radius);

      if (!isVisible) {
        this.hide();
      }

      circle.on('click', this.onClick);
      circle.on('hide', this.onHide);
      circle.on('show', this.onShow);

    },
    /**
     * 清空
     */
    clear() {
      this.hide();
      const { mapOverlay, mapEditor } = this;

      if (mapEditor) {
        mapEditor.close();
      }

      if (mapOverlay) {
        // 参数为null时，在地图上移除当前折线
        mapOverlay.setMap(null);
      }
      this.mapEditor = null;
      this.mapOverlay = null;
    },

    /**
     * 打开编辑器
     */
    openEditor() {
      const { mapEditor } = this;
      mapEditor?.open();
    },
    /**
     * 关闭编辑器
     */
    closeEditor() {
      const { mapEditor } = this;
      mapEditor?.close();
    },

    async initEditor() {

      // 等待地图实例化完成
      await sleepIf(8000, () => !isNil(this.getMap()));
      const map = this.getMap();
      const { mapOverlay, editIsOpen } = this;

      // 等待插件加载完成
      await loadPlugin(['AMap.CircleEditor']);

      const editor = new AMap.CircleEditor(map, mapOverlay);
      this.mapEditor = editor;

      editor.on('move', this.onEditMoving);
      editor.on('adjust', this.onEditAdjust);
      editor.on('end', this.onEditEnd);

      editIsOpen && this.openEditor();

    },
    /**
     * 清空编辑器
     */
    clearEditor() {
      this.closeEditor();
      if (this.mapEditor) {
        const map = this.getMap();
        // NOTE: 未验证是否有用
        map && map.removeControl(this.mapEditor);
      }
      this.mapEditor = null;
    },


    onClick(e) {
      const { path } = this;
      this.$emit('click', path, e);
    },
    onHide(e) {
      const { path } = this;
      this.$emit('hide', path, e);
    },
    onShow(e) {
      const { path } = this;
      this.$emit('show', path, e);
    },

    onEditMoving(e) {
      this.$emit('editMoving', e);
    },
    onEditAdjust(e) {
      this.$emit('editAdjust', e);
    },
    onEditEnd(e) {
      this.$emit('editEnd', e);
    },

    /**
     * 将 `[[southWest],[northEast]]` 转为 `Bounds`类型
     * southWest: 西南角经纬度 [lng, lat]
     * northEast: 东北角经纬度 [lng, lat]
     */
    toBounds(lngLats) {
      const [] = lngLats;

      const southWest = new AMap.LngLat(...lngLats[0]);
      const northEast = new AMap.LngLat(...lngLats[1]);

      return new AMap.Bounds(southWest, northEast);
    },
    /**
     * 将 `Bounds`类型 转为 数组
     */
    toLngLats(bounds) {
      // 取西南角坐标。
      const southWest = bounds.getSouthWest();
      // 获取东北角坐标
      const northEast = bounds.getNorthEast();

      return [
        [southWest.getLng(), southWest.getLat()],
        [northEast.getLng(), northEast.getLat()]
      ];
    },

    getMap() {
      return this._getMapRootMap?.();
    },
    /**
     * 获取地图父节点
     */
    getMapParent() {
      this._getMapRoot?.();
    },

  }
}
</script>