<template>
  <!-- 信息窗体 InfoWindow -->
  <div v-show="false">
    <div :lnglat="lngLat"
      ref="windowRef"
      :style="outerStyle"
      class="window_outer">
      <!-- 窗体内容区 -->
      <div class="WindowContent">
        <slot></slot>
      </div>

      <!-- 定位箭头 -->
      <div class="AnchorArrow"></div>

      <div v-if="showClose"
        class="close_icon"
        title="关闭"
        @click.stop="close">
        <span class="el-icon-close"></span>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 信息窗体
 * 同一个地图实例每次只能打开一个信息窗体。
 */

import { isNil } from 'lodash';
import { sleepIf } from '@/utils/sleep';

// 默认偏移量
const defaultOffset = [0, 0];

export default {
  name: 'AInfoWindow',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
    // function 地图根组件
    '_getMapRoot',
  ],
  model: {
    prop: 'isVisible',
  },
  emits: [
    // 窗体打卡事件
    'open',
    // 窗体关闭事件
    'close',
    'change',
  ],
  props: {
    // 是否打开窗体
    isVisible: {
      type: Boolean,
      default: true,
    },
    // InforWindowOptions
    // https://lbs.amap.com/api/javascript-api/reference/infowindow#InforWindowOptions
    ctorOpts: {
      type: Object,
      default: null,
    },
    /**
     * 信息窗体显示基点位置
     * 错误用法: 使用字面量 `lnglat="[lng,lat]"`
     */
    lngLat: {
      // AMap.LngLat | [lng,lat]
      type: [Array, Object],
      default: null,
    },
    // 信息窗体显示位置偏移量。默认基准点为信息窗体的底部中心
    offset: {
      type: Array,
      default() {
        return defaultOffset;
      },
    },
    // 窗体宽度
    width: {
      type: Number,
      default: 200,
    },
    // 窗体高度
    height: {
      type: Number,
      default: 200,
    },
    /**
     * 显示关闭Icon
     */
    showClose: {
      type: Boolean,
      default: false,
    },
    /**
     * 窗体文本颜色
     */
    color: {
      type: String,
      default: "#000000",
    },
    /**
     * 是否自动调整窗体到视野内
     */
    autoMove: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    // 信息窗口实例
    this.mapWindow = null;
    return {

    };
  },
  computed: {
    /**
     * 窗体cssStyle
     */
    outerStyle() {
      const { width, height, color } = this;
      return {
        width: `${ width }px`,
        height: height ? `${ height }px` : 'auto',
        color: `${ color }`
      };
    }
  },
  watch: {
    // 控制打开关闭窗体
    isVisible(visible) {
      const isOpen = this.getIsOpen();
      if (visible === isOpen) return;
      const { lngLat } = this;

      if (visible) {
        this.open(lngLat);
      } else {
        this.close();
      }
    },

    // 改变信息窗体位置
    lngLat(val) {
      const { isVisible } = this;
      if (!isVisible) return;

      const isOpen = this.getIsOpen();
      if (isOpen) {
        this.setPosition(val);
      } else {
        this.open(val);
      }
    }
  },
  beforeMount() {
    this.onChange = this.onChange.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    async init() {
      await this.initWindow();
      // 立即打开
      if (this.isVisible) {
        this.open(this.lngLat);
      }
    },

    // 获取信息窗体是否打开
    getIsOpen() {
      const { mapWindow } = this;
      return mapWindow?.getIsOpen();
    },

    // 设置信息窗体显示基点位置
    setPosition(lngLat) {
      if (isNil(lngLat)) return;

      const { mapWindow } = this;
      mapWindow.setPosition(lngLat);
    },

    // 打开窗体
    open(lngLat) {

      if (isNil(lngLat)) return false;

      const { mapWindow } = this;
      const map = this.getMap();
      if (isNil(mapWindow) || isNil(map)) return false;

      mapWindow.open(map, lngLat);

      return this.getIsOpen();
    },
    // 关闭窗体
    close() {
      const { mapWindow } = this;
      mapWindow.close();
      this.$emit('input', false);
    },


    // 初始化地图窗体
    async initWindow() {

      // 等待地图实例化完成
      await sleepIf(8000, () => !isNil(this.getMap()));

      const { windowRef } = this.$refs;
      const { ctorOpts, offset, autoMove } = this;

      // https://lbs.amap.com/api/javascript-api/reference/infowindow
      const mapWindow = new AMap.InfoWindow({
        isCustom: true,
        content: windowRef,
        // 鼠标点击地图后不关闭信息窗体
        closeWhenClickMap: false,
        // 信息窗体锚点
        anchor: 'bottom-center',
        offset: new AMap.Pixel(...(offset || defaultOffset)),
        // 不显示窗体阴影
        showShadow: false,
        // 信息窗体关闭时，是否将其Dom元素从页面中移除
        retainWhenClose: true,
        // 是否自动调整窗体到视野内
        autoMove: autoMove,
        ... (ctorOpts || {}),
      });

      mapWindow.on('open', this.onOpen);
      mapWindow.on('close', this.onClose);
      mapWindow.on('change', this.onChange);

      this.mapWindow = mapWindow;
    },

    onOpen(e) {
      const { lngLat } = this;
      this.$emit('open', lngLat, e);
    },
    onClose(e) {
      const { lngLat } = this;
      this.$emit('close', lngLat, e);
    },
    onChange(e) {
      const { lngLat } = this;
      this.$emit('change', lngLat, e);
    },

    // 销毁窗体
    clear() {
      this.close();

      const { mapWindow } = this;
      if (mapWindow) {
        mapWindow.off('change', this.onChange);
        mapWindow.off('open', this.onOpen);
        mapWindow.off('change', this.onClose);
      }
      this.mapWindow = null;

      const map = this.getMap();
      if (map) {
        // 清除地图上的信息窗体。
        // https://lbs.amap.com/api/javascript-api/reference/map
        map.clearInfoWindow();
      }
    },

    /**
     * 获取地图实例
     */
    getMap() {
      return this._getMapRootMap?.();
    },
    /**
     * 获取地图父节点
     */
    getMapParent() {
      this._getMapRoot?.();
    }
  }
}
</script>

<style lang="scss" scoped>
.window_outer {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
}
.close_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & > i {
    font-size: 18px;
    font-weight: bold;
  }
}

.WindowContent {
  box-sizing: border-box;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #dcdfe6;
}

.AnchorArrow {
  margin: 0px auto 0;
  background: url(https://webapi.amap.com/ui/1.0/ui/overlay/SimpleInfoWindow/assets/sharp.png)
    no-repeat -5px -16px;
  width: 18px;
  height: 13px;
}
</style>
