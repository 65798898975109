<template>
  <div class="AMapWrapper">
    <!-- 地图容器 -->
    <div ref="mapRef" class="AMapContainer"></div>

    <!-- 向子组件提供插槽 -->
    <div mapPlaceholder v-show="false">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import { isNil, throttle } from 'lodash';
import { sleepIf } from '@/utils/sleep';
import { createMap, loadMapControl } from './utils/createMap';

const defaultCenter = [116.397476, 39.909162];
const defaultZoom = 14;

export default {
  name: 'AMap',
  provide() {
    return {
      _getMapRoot: () => {
        return this;
      },
      // 向子组件提供地图实例
      _getMapRootMap: this.getMap,
    };
  },
  emits: [
    // 地图加载完成
    'loaded',
    // 地图点击事件
    'click',
    // 地图双击事件
    'dblclick',
    // 地图移动事件
    'mapmove',
    'movestart',
    'moveend',
    // 地图缩放事件
    'zoomchange',
    'zoomstart',
    'zoomend',
    'dragstart',
    'dragging',
    'dragend',
  ],
  props: {
    // 地图属性, type: MapOptions
    // https://lbs.amap.com/api/javascript-api/reference/map
    mapProps: {
      type: Object,
      default: null,
    },
    /**
     * 地图中心点坐标值
     * LngLat
     * NOTE: 使用时请使用变量, 不要使用字面量 错误用法: `center = "[lng,lat]"`
     */
    center: {
      type: Array,
      default: null,
    },
    /**
     * 地图显示的缩放级别
     * 取值范围[3-18]
     */
    zoom: {
      type: Number,
      default: null,
      validator(val) {
        return val >= 3 && val <= 18;
      }
    },
    mapStyle: {
      type: String,
      default: 'amap://styles/normal'
    }
  },
  data() {

    // 地图实例
    this.map = null;

    return {
      mapCount: 0,
    };
  },
  watch: {
    center(val, oldVal) {
      this.setCenter(val);
    },
    zoom(val) {
      this.setZoom(val);
    }
  },
  beforeCreate() {

  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
    this.onDblclick = this.onDblclick.bind(this);
    this.onMapMove = this.onMapMove.bind(this);
    this.onMoveStart = this.onMoveStart.bind(this);
    this.onMoveEnd = this.onMoveEnd.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onZoomStart = this.onZoomStart.bind(this);
    this.onZoomEnd = this.onZoomEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragging = this.onDragging.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.panTo = throttle(this.panTo, 500).bind(this);
    this.setFitView = throttle(this.setFitView, 500).bind(this);
    this.setCenter = throttle(this.setCenter, 500).bind(this);
    this.contains = throttle(this.contains, 500).bind(this);
    this.setZoom = throttle(this.setZoom, 500).bind(this);
  },
  mounted() {
    // 初始化
    this.initMap();
  },
  // 销毁
  beforeDestroy() {

    const { map } = this;
    if (map) {
      map.off('click', this.onClick);
      map.off('dblclick', this.onDblclick);
      map.off('mapmove', this.onMapMove);
      map.off('movestart', this.onMoveStart);
      map.off('moveend', this.onMoveEnd);
      map.off('zoomchange', this.onZoomChange);
      map.off('zoomstart', this.onZoomStart);
      map.off('zoomend', this.onZoomEnd);
      map.off('dragstart', this.onDragStart);
      map.off('dragging', this.onDragging);
      map.off('dragend', this.onDragEnd);
    }

    this.clearMap();
    this.destroy();
  },
  methods: {

    /**
     * 地图中心点平移至指定点位置
     @param {boolean} inView - 在视野内是否平移, 默认`false`不平移, `true`: 在视野内不平移
     */
    panTo(lngLat, inView = false) {
      const { map } = this;
      if (inView || !this.contains(lngLat)) {
        map?.panTo(lngLat);
      }
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView(...args) {
      const { map } = this;
      map?.setFitView(...args);
    },
    /**
     * 指定点坐标是否在视野范围内
     * @param {LngLat} lngLat - 经纬度
     */
    contains(lngLat) {
      const { map } = this;
      const bounds = map?.getBounds();
      return bounds?.contains(lngLat);
    },
    /**
     * 设置地图显示的中心点
     */
    setCenter(center) {
      const { map } = this;
      map?.setCenter(center);
    },
    /**
     * 设置地图显示的缩放级别
     */
    setZoom(zoom) {
      const { map } = this;
      map?.setZoom(zoom);
    },
    /**
     * 地图放大一级显示
     */
    zoomIn() {
      const { map } = this;
      map?.zoomIn();
    },
    /**
     * 地图缩小一级显示
     */
    zoomOut() {
      const { map } = this;
      map?.zoomOut();
    },

    // 初始化地图
    async initMap() {
      // 最多等待8秒, 直到脚本加载完成
      await sleepIf(8000, () => !isNil(window.AMap));

      const { mapRef } = this.$refs;
      const { mapProps, center, zoom, mapStyle } = this;

      // https://lbs.amap.com/api/javascript-api/reference/map
      // 创建地图实例
      const map = createMap(mapRef, {
        center: center || defaultCenter,
        zoom: zoom || defaultZoom,
        mapStyle: mapStyle,
        ...(mapProps || {})
      });
      // 加载地图控件
      loadMapControl(map);
      this.map = map;

      this.$emit('loaded', map);

      // 鼠标左键单击事件 
      map.on('click', this.onClick);
      // 鼠标左键双击事件
      map.on('dblclick', this.onDblclick);
      // 地图平移时触发事件
      map.on('mapmove', this.onMapMove);
      // 地图平移开始时触发
      map.on('movestart', this.onMoveStart);
      // 地图移动结束后触发，包括平移，以及中心点变化的缩放。如地图有拖拽缓动效果，则在缓动结束后触发
      map.on('moveend', this.onMoveEnd);
      // 地图缩放级别更改后触发
      map.on('zoomchange', this.onZoomChange);
      // 缩放开始时触发
      map.on('zoomstart', this.onZoomStart);
      // 缩放停止时触发
      map.on('zoomend', this.onZoomEnd);
      // 开始拖拽地图时触发
      map.on('dragstart', this.onDragStart);
      // 拖拽地图过程中触发
      map.on('dragging', this.onDragging);
      // 止拖拽地图时触发
      map.on('dragend', this.onDragEnd);

    },
    /**
     * 清空地图
     */
    clearMap() {
      const { map } = this;
      map?.clearMap();
    },
    /**
     * 销毁地图
     */
    destroy() {
      const { map } = this;
      map?.destroy();
    },
    onClick(e) {
      this.$emit('click', e);
    },
    onDblclick(e) {
      this.$emit('dblclick', e);
    },
    onMapMove(e) {
      this.$emit('mapmove', e);
    },
    onMoveStart(e) {
      this.$emit('movestart', e);
    },
    onMoveEnd(e) {
      this.$emit('moveend', e);
    },
    onZoomChange(e) {
      this.$emit('zoomchange', e);
    },
    onZoomStart(e) {
      this.$emit('zoomstart', e);
    },
    onZoomEnd(e) {
      this.$emit('zoomend', e);
    },
    onDragStart(e) {
      this.$emit('dragstart', e);
    },
    onDragging(e) {
      this.$emit('dragging', e);
    },
    onDragEnd(e) {
      this.$emit('dragend', e);
    },
    getMap() {
      return this.map;
    }
  }

}
</script>

<style lang="scss" scoped>
.AMapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.AMapContainer {
  width: 100%;
  height: 100%;
}
</style>