<template>
  <div MapClusterer v-show="false">
    <MapMarker
      noMap
      :ref="getMarkerRefName(point)"
      v-for="point in points"
      :key="getPointKey(point)"
      :lngLat="[point.lng, point.lat]"
      :offset="markerOffset"
      @click="$emit('markerClick', point)"
    >
      <slot name="marker" :point="point"></slot>
    </MapMarker>
  </div>
</template>

<script>
/**
 * 地图聚合点
 */


import { isNil, throttle } from 'lodash';
import { sleep, sleepIf } from '@/utils/sleep';
import { loadPlugin } from './utils/loadPlugin';
import { diffPatch } from '@/utils/diffPatch';
import MapMarker from './MapMarker.vue';


export default {
  name: 'MClusterer',
  components: {
    MapMarker,
  },
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
  ],
  emits: [
    // 聚合点击事件
    'click',
    // 聚合点点击事件
    'markerClick',
  ],
  props: {
    /**
     * @param {{lng,lat}[]} param - 聚合点集合
     */
    points: {
      type: Array,
      default: [],
    },
    /**
     * 唯一键值
     */
    pointKey: {
      type: String,
      default: '',
    },
    /**
     * 点标记显示位置偏移量，默认值为Pixel(-10,-34)
     * 错误用法: 使用字面量 `markerOffset="[-10,-34]"`
     */
    markerOffset: {
      // Pixel
      type: Array,
      default: null,
    },
    /**
     * 聚合计算时网格的像素大小，默认60
     */
    gridSize: {
      type: Number,
      default: 9,
    },
    /**
     * 聚合的最小数量。默认值为2，即小于2个点则不能成为一个聚合
     */
    minClusterSize: {
      type: Number,
      default: 10
    },
    /**
     * 最大的聚合级别，大于该级别就不进行相应的聚合。默认值为18，
     * 即小于18级的级别均进行聚合，18及以上级别不进行聚合
     */
    maxZoom: {
      type: Number,
      default: 18,
    },
    /**
     * 聚合点的图标位置是否是所有聚合内点的中心点。
     * 默认为否，即聚合点的图标位置位于聚合内的第一个点处
     */
    averageCenter: {
      type: Boolean,
      default: false,
    },
    /**
     * 点击聚合点时，是否散开，默认值为：true
     */
    zoomOnClick: {
      type: Boolean,
      default: true,
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    autoFitView: {
      type: Boolean,
      default: false,
    },
    /**
     * 聚合事件
     */
    pointRender: {
      type: Function,
      default: null,
    },
  },
  data() {
    // 聚合实例
    this.mapOverlay = null;

    return {};
  },
  computed: {

  },
  watch: {
    points: {
      async handler(val, oldVal) {
      // NOTE: 聚合点数据更新有两种方式
      // 1:　计算出新数据和旧数据之间的差异(新增,删除,更新)，然后相应操作
      // 2: 直接清除旧数据, 用新的数据重新添加聚合点

      // NOTE: 这里选用方式1
        await sleep(300);
        this.updateClusterMarkers();
      },
    }
  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { mapOverlay } = this;
    if (mapOverlay) {
      mapOverlay.off('click', this.onClick);
      mapOverlay.clearMarkers();
    }
    this.mapOverlay = null;
  },
  methods: {
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      const map = this.getMap();
      map?.setFitView();
    },
    getMarkerRef(point) {
      return this.$refs[this.getMarkerRefName(point)][0];
    },
    getMarkerRefName(point) {
      return `ref_${this.getPointKey(point)}`;
    },
    getPointKey(point) {
      const { pointKey } = this;
      return point[pointKey];
    },


    /**
     * 获取当前所有点标记
     */
    getMarkers() {
      const { points } = this;
      return points.map(point => this.getMarkerRef(point).getMarker());
    },

    // 更新聚合点
    updateClusterMarkers() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;

      // 获取该点聚合中的点标记集合
      const clusterMarkers = mapOverlay.getMarkers();
      const markers = this.getMarkers();

      const [addMarkers, updateMarkers, delMarkers] = diffPatch(
        markers,
        clusterMarkers,
        (newMarker, oldMarker) => newMarker === oldMarker
      );


      // 删除点标记
      if (delMarkers.length > 0) {
        mapOverlay.removeMarkers(delMarkers);
      }

      // 添加点标记
      if (addMarkers.length > 0) {
        mapOverlay.addMarkers(addMarkers);
      }

    },


    async init() {
      await this.initClusterer();
      await sleep(500);
      this.updateClusterMarkers();
    },
    async initClusterer() {
      await loadPlugin(['AMap.MarkerClusterer']);
      await sleepIf(8000, () => !isNil(this.getMap()));

      // 加载聚合插件

      const map = this.getMap();

      const {
        gridSize,
        minClusterSize,
        maxZoom,
        averageCenter,
        zoomOnClick,
        pointRender
      } = this;

      // https://lbs.amap.com/api/javascript-api/reference/plugin#AMap.MarkerClusterer
      const cluster = new AMap.MarkerClusterer(map, [], {
        gridSize,
        minClusterSize,
        maxZoom,
        averageCenter,
        zoomOnClick,
        renderClusterMarker: pointRender,
        renderMarker: pointRender,
      });
      cluster.setMap(map);

      this.mapOverlay = cluster;

      cluster.on('click', this.onClick);

    },

    onClick(e) {
      this.$emit('click', e);
    },

    getMap() {
      return this._getMapRootMap?.();
    },
  }
}
</script>
