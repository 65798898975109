<template>
  <div>
    <!-- 轨迹线 -->
    <MapPolyline
      ref="polylineRef"
      v-bind="propCtorOpts"
      :path="linePath"
      :autoFitView="true"
    ></MapPolyline>

    <!-- 已经走过的轨迹线 -->
    <MapPolyline
      ref="passedPolylineRef"
      v-bind="propPassedOpts"
      v-model="passedVisible"
      :path="passedPath"
    ></MapPolyline>

    <!-- 移动的点标记 -->
    <MapMarker
      ref="markerRef"
      v-if="linePath.length > 0"
      v-bind="markerOpts||{}"
      v-model="moveMarkerVisible"
      :lngLat="linePath[0]"
      :offset="markerOffset"
      :autoRotation="autoRotation"
      isTop
      @moving="onMoving"
      @moveend="onMoveEnd"
      @movealong="onMoveAlong"
    >
      <slot name="moveMarker"></slot>
    </MapMarker>

    <!-- 开始点标记 -->
    <MapMarker
      v-if="linePath.length > 0"
      v-bind="startMarkerOpts||{}"
      :isVisible="showStartEnd"
      :offset="[1, -14]"
      :lngLat="linePath[0]"
      isTop
    >
      <slot name="startMarker"></slot>
    </MapMarker>

    <!-- 结束点标记 -->
    <MapMarker
      v-if="linePath.length > 0"
      v-bind="endMarkerOpts||{}"
      :offset="[1, -14]"
      :isVisible="showStartEnd"
      :lngLat="linePath[linePath.length - 1]"
      isTop
    >
      <slot name="endMarker"></slot>
    </MapMarker>
  </div>
</template>

<script>
// NOTE: 纠偏模式, 轨迹回放的`moving`的轨迹不能对应到原始的轨迹点
// NOTE: 及已经走过的轨迹不能在原始轨迹数组中找到

import { throttle } from 'lodash';
import MapPolyline from './MapPolyline.vue';
import MapMarker from './MapMarker.vue';
import { graspDriving } from './utils';

export default {
  name: 'AMapTrack',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
  ],
  components: {
    MapPolyline,
    MapMarker,
  },
  props: {
    /**
     * 轨迹数组
     * @param {Array<{lng,lat,angle,speed,time}>}
     * @param {number} lng - 经度
     * @param {number} lat - 纬度
     * @param {number} angle - 方向角度 [0,360], 可不填
     * @param {number} speed - 速度, 可不填
     * @param {number} time - 时间(秒), 可不填
     */
    path: {
      type: Array,
      default: [],
      required: true,
    },
    /**
     * 轨迹是否纠偏
     */
    isGrasp: {
      type: Boolean,
      default: false,
    },
    /**
     * 播放速度
     */
    speed: {
      type: Number,
      default: 200,
      validator(val) {
        return val > 0;
      }
    },

    /**
     * 显示开始结束点
     */
    showStartEnd: {
      type: Boolean,
      default: true,
    },

    /**
     * 轨迹线参数
     * PolylineOptions
     * https://lbs.amap.com/api/javascript-api/reference/overlay#polyline
     */
    ctorOpts: {
      type: Object,
      default: null,
    },
    /**
     * 已经走过的的轨迹参数
     * PolylineOptions
     */
    passedOpts: {
      type: Object,
      default: null,
    },
    /**
     * 移动的点标记参数
     * https://lbs.amap.com/api/javascript-api/reference/overlay#marker
     */
    markerOpts: {
      type: Object,
      default: null,
    },
    markerOffset: {
      type: Array,
      default: ()=>[4,-16],
    },
    /**
     * 开始点标记参数
     */
    startMarkerOpts: {
      type: Object,
      default: null,
    },
    /**
     * 结束点标记参数
     */
    endMarkerOpts: {
      type: Object,
      default: null,
    },
    markerClustererOffset: {
      type: Array,
      default: ()=> [0,0]
    },
    autoRotation: {
      type: Boolean,
      default: false
    }
  },
  data() {

    return {
      // 轨迹线
      linePath: [],

      // 是否正在移动
      isMoving: false,
      // 移动到指定位置点的下标
      moveToIndex: 0,
      // 走过的轨迹
      passedPath: [],
      // 走过的轨迹是否显示在地图上
      passedVisible: false,

      // 移动点标记是否显示在地图上
      moveMarkerVisible: false,
    };
  },
  computed: {
    // 轨迹线参数
    propCtorOpts() {
      const { ctorOpts } = this;
      return {
        showDir: true,
        strokeColor: "#28F",  //线颜色
        strokeWeight: 6,      //线宽
        ...(ctorOpts || {})
      };
    },
    // 走过的轨迹线参数
    propPassedOpts() {
      const { passedOpts } = this;
      return {
        showDir: false,
        strokeColor: "#AF5",  //线颜色
        strokeWeight: 6,      //线宽
        ...(passedOpts || {})
      };
    },
  },
  watch: {
    path() {
      this.setLinePath();
    }
  },
  beforeMount() {
    this.handAlongOrEnd = throttle(this.handAlongOrEnd, 30).bind(this);
  },
  mounted() {
    this.setLinePath();
  },
  beforeDestroy() {
    this.isMoving = false;
    this.moveToIndex = 0;
    this.passedPath = [];
    this.passedVisible = false;
    this.moveMarkerVisible = false;
  },
  methods: {

    /**
     * 开始移动
     */
    async startMove() {
      this.isMoving = true;
      this.moveToIndex = 0;
      this.passedPath = [];
      // 显示移动点标记
      this.moveMarkerVisible = true;
      // 显示移动过的轨迹
      this.passedVisible = true;
      await this.$nextTick();
      this.moveToNext();
    },

    /**
     * 下一个有效的移动位置点下标
     */
    findNextIndex() {
      const { linePath } = this;
      let { moveToIndex } = this;
      let current = linePath[moveToIndex];

      while (moveToIndex < linePath.length - 1) {
        moveToIndex++;
        const next = linePath[moveToIndex];
        // 经纬度不同
        if (!(current[0] === next[0] && current[1] === next[1])) {
          return moveToIndex;
        }
      }
      return -1;
    },


    /**
     * 移动到下一个点
     * @return {Number} `0`: 移动结束;
     */
    moveToNext() {
      const { linePath, speed, isMoving } = this;
      const { markerRef } = this.$refs;

      if (!isMoving) return -1;
      if (!markerRef) return -1;

      const nextIndex = this.findNextIndex();

      // 移动结束
      if (nextIndex < 0) return 0;

      // 当前点
      const prevPoint = linePath[this.moveToIndex];
      // 下一个点
      const nextPoint = linePath[nextIndex];

      this.moveToIndex = nextIndex;
      // 记录下播放到的点下标
      // markerRef.moveTo(nextPoint, speed);
      markerRef.moveAlong([prevPoint, nextPoint], speed);

      return 1;
    },

    /**
     * 停止点标记移动
     */
    stopMove() {
      const { markerRef } = this.$refs;
      this.isMoving = false;
      markerRef?.stopMove();
    },
    replacementMove() {
      const { markerRef } = this.$refs;
      this.isMoving = false;
      this.moveToIndex = 0;
      this.passedPath = [];
      // 显示移动点标记
      this.moveMarkerVisible = false;
      // 显示移动过的轨迹
      this.passedVisible = false;
      markerRef?.stopMove();
      this.$emit('onReplacement')
    },
    /**
     * 暂停点标记移动
     */
    pauseMove() {
      const { markerRef } = this.$refs;
      this.isMoving = false;
      markerRef?.pauseMove();
    },
    /**
     * 点标记暂停移动后, 恢复移动
     */
    async resumeMove() {
      const { markerRef } = this.$refs;
      this.isMoving = true;
      markerRef?.resumeMove();
    },
    /**
     * 移动点标记, 恢复到开始位置
     */
    resetMove() {
      const { markerRef } = this.$refs;
      this.isMoving = false;
      markerRef?.stopMove();
      this.moveToIndex = 0;
      this.passedPath = [];
      this.moveMarkerVisible = false;
      this.passedVisible = false;
    },
    /**
     * 地图中心点平移至指定点位置
     * `inView`: false, 在视野外才会平移
     */
    panTo(lngLat, inView = false) {
      const map = this.getMap();
      if (!map) return;
      const bounds = map.getBounds();
      if (inView || !bounds.contains(lngLat)) {
        map.panTo(lngLat);
      }
    },

    /**
     * 设置轨迹路径数组
     */
    async setLinePath() {

      const { path, isGrasp } = this;
      const clonePath = [...path];

      this.passedPath = [];

      if (isGrasp && clonePath.length > 3) { // 纠偏
        try {
          const result = await graspDriving(clonePath);
          this.linePath = result.path;
          return;
        } catch (err) {
          console.error(err);
        }
      }

      this.linePath = clonePath.map(point => [point.lng, point.lat]);
    },
    /**
     * 点标记移动过的位置点
     */
    onMoving(e) {

      const { isMoving, moveToIndex, linePath } = this;
      if (!isMoving) return;
      const movedPath = e.passedPath.map(point => [point.lng, point.lat]);
      const passedPath = linePath.slice(0, moveToIndex);

      // `passedPath` 最后一个点和 `movePath` 的第一个点相同, 则将 `movePath` 的第一个点删除
      if (passedPath[passedPath.length - 1][0] === movedPath[0][0]
        && passedPath[passedPath.length - 1][1] === movedPath[0][1]) {
        movedPath.shift();
      }

      passedPath.push(...movedPath);
      this.passedPath = [...passedPath];

      // 地图视野平移到移动的点
      this.panTo([...passedPath[passedPath.length - 1]]);

      // `pathedPath`: 移动的轨迹路径
      // `moveToIndex`: 移动标记点下标(目标点)
      this.$emit('moving', passedPath, moveToIndex, e);
    },
    /**
     * 移动结束
     * 点标记执行moveTo动画结束时触发事件，也可以由moveAlong方法触发
     */
    async onMoveEnd(e) {
      this.handAlongOrEnd();
    },
    /**
     * 最后一次移动动画, 表示结束
     */
    onMoveAlong(e) {
      this.handAlongOrEnd();
    },

    handAlongOrEnd() {

      // 0: 移动结束
      if (this.moveToNext() === 0) {
        const { linePath } = this;
        this.isMoving = false;
        this.passedPath = linePath.slice(0, linePath.length);
        this.$emit('moveEnd');
      }

    },


    getMap() {
      return this._getMapRootMap?.();
    },
  }


}
</script>