<template></template>

<script>
import { isNil, throttle } from 'lodash';
import { sleepIf } from '@/utils/sleep';
import { loadPlugin } from './utils/loadPlugin';

export default {
  name: 'APolygon',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
  ],
  emits: [
    'click',
    'show',
    'hide',
    'editAdd',
    'editAdjust',
    'editRemove',
    'editEnd'
  ],
  model: {
    prop: 'isVisible',
  },
  props: {
    // 是否显示
    isVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * PolygonOptions
     */
    ctorOpts: {
      type: Object,
      default: null,
    },
    /**
     * 是否可编辑
     */
    editable: {
      type: Boolean,
      default: false,
    },
    /**
     * 开启编辑状态
     */
    editIsOpen: {
      type: Boolean,
      default: false,
    },
    /**
      * 自动缩放地图到合适的视野级别
      */
    autoFitView: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否自动偏移到中心, 与`autoFitView`互斥, 比`autoFitView`的优先级低
     */
    autoPanTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 在视野内是否平移
     */
    panToInView: {
      type: Boolean,
      default: false,
    },
    /**
     * 坐标数组
     */
    path: {
      // LngLat[]
      type: Array,
      default: null,
      required: true,
    },
    /**
     * 线条颜色，使用16进制颜色代码赋值
     */
    strokeColor: {
      type: String,
      default: '#FF33FF', // '#006600'
    },
    /**
     * 线条宽度，单位：像素
     */
    strokeWeight: {
      type: Number,
      default: 6,
    },
    /**
     * 线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
     */
    strokeOpacity: {
      type: Number,
      default: 0.2,
    },
    /**
     * 线样式，实线:solid，虚线:dashed
     */
    strokeStyle: {
      type: String,
      default: 'solid',
    },
    /**
     * 多边形填充颜色，使用16进制颜色代码赋值
     */
    fillColor: {
      type: String,
      default: '#1791f'
    },
    /**
     * 多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。
     */
    fillOpacity: {
      type: Number,
      default: 0.4,
    }

  },
  data() {
    // 覆盖物 多边形实例
    this.mapOverlay = null;
    // 折线、多边形编辑插件 实例
    this.polyEditor = null;
    return {};
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.show();
      } else {
        this.hide();
      }
    },
    async editable(val) {
      const { polyEditor } = this;
      if (val) {
        polyEditor || this.initEditor();
      }
    },
    async editIsOpen(isOpen) {
      const { editable } = this;
      if (isOpen) {
        if (editable) {
          // 等图形画好
          await this.$nextTick();
          this.openEditor();
        }
      } else {
        this.closeEditor();
      }
    },
    path(val) {
      this.setPath(val || []);
    }
  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEditAdd = this.onEditAdd.bind(this);
    this.onEditAdjust = this.onEditAdjust.bind(this);
    this.onEditRemove = this.onEditRemove.bind(this);
    this.onEditEnd = this.onEditEnd.bind(this);

    this.fitViewOrPanTo = throttle(this.fitViewOrPanTo, 500).bind(this);

  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { mapOverlay, polyEditor } = this;

    if (polyEditor) {
      polyEditor.off('addnode', this.onEditAdd);
      polyEditor.off('adjust', this.onEditAdjust);
      polyEditor.off('removenode', this.onEditRemove);
      polyEditor.off('end', this.onEditEnd);
    }

    if (mapOverlay) {
      mapOverlay.off('click', this.onClick);
      mapOverlay.off('hide', this.onHide);
      mapOverlay.off('show', this.onShow);
    }
    this.clearEditor();
    this.clear();
  },
  methods: {

    async init() {
      const { editable } = this;
      await this.initPolygon();

      if (editable) {
        await this.initEditor();
      }
    },

    fitViewOrPanTo() {
      const { autoFitView, autoPanTo, panToInView } = this;
      if (autoFitView) {
        this.setFitView();
      } else if (autoPanTo) {
        // 平移到中心点
        this.panToCenter(panToInView);
      }
    },
    /**
     * 地图中心点平移至多边形的中心点
     * `inView`: false, 在视野外才会平移
     */
    panToCenter(inView = false) {
      const map = this.getMap();
      if (!map) return;
      // 获取多边形的中线点
      const center = this.getBounds()?.getCenter();
      const bounds = map.getBounds(); // 判断是否在地图视野内
      if (inView || !bounds.contains(center)) {
        map.panTo(center);
      }
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      const map = this.getMap();
      map?.setFitView(mapOverlay);
    },


    show() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.show();
      this.fitViewOrPanTo();
    },
    hide() {
      const { mapOverlay } = this;
      mapOverlay?.hide();
    },

    /**
     * 设置组成该折线的节点数组
     * @param {LngLat[]} path
     */
    setPath(path) {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.setPath(path || []);
      this.fitViewOrPanTo();
    },
    getPath() {
      const { mapOverlay } = this;
      return mapOverlay?.getPath();
    },
    /**
     * 获取多边形的面积（单位：平方米）
     */
    getArea() {
      const { mapOverlay } = this;
      return mapOverlay?.getArea();
    },
    /**
     * 获取当前多边形的矩形范围对象
     * https://lbs.amap.com/api/javascript-api/reference/core#Bounds
     * @return {Bounds}
     */
    getBounds() {
      const { mapOverlay } = this;
      return mapOverlay?.getBounds();
    },
    /**
     * 判断指定点坐标是否在多边形范围内
     * @param {LngLat[]} lngLat
     * @return {Boolean} 
     */
    contains(lngLat) {
      const { mapOverlay } = this;
      return mapOverlay?.contains(lngLat);
    },

    async initPolygon() {
      await sleepIf(8000, () => !isNil(this.getMap()));

      const {
        path,
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        ctorOpts,
        isVisible
      } = this;
      const map = this.getMap();

      // https://lbs.amap.com/api/javascript-api/reference/overlay#polygon
      const polygon = new AMap.Polygon({
        draggable: false,
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        map,

        ...(ctorOpts || {})
      });
      this.mapOverlay = polygon;

      this.setPath(path);

      if (!isVisible) {
        this.hide();
      }

      polygon.on('click', this.onClick);
      polygon.on('hide', this.onHide);
      polygon.on('show', this.onShow);

    },
    clear() {
      this.hide();
      const { mapOverlay, polyEditor } = this;

      if (polyEditor) {
        polyEditor.close();
      }


      if (mapOverlay) {
        // 参数为null时，在地图上移除当前折线
        mapOverlay.setMap(null);
      }
      this.polyEditor = null;
      this.mapOverlay = null;
    },

    openEditor() {
      const { polyEditor } = this;
      polyEditor?.open();
    },
    closeEditor() {
      const { polyEditor } = this;
      polyEditor?.close();
    },

    async initEditor() {


      await sleepIf(8000, () => !isNil(this.getMap()));
      const map = this.getMap();
      const { mapOverlay, editIsOpen } = this;

      // 等待插件加载完成
      await loadPlugin(['AMap.PolyEditor']);

      const editor = new AMap.PolyEditor(map, mapOverlay);
      this.polyEditor = editor;

      // 通过鼠标在折线上增加一个节点或在多边形上增加一个顶点时触发此事件
      editor.on('addnode', this.onEditAdd);
      // 鼠标调整折线上某个节点或多边形上某个顶点的位置时触发此事件
      editor.on('adjust', this.onEditAdjust);
      // NOTE: 双击某个编辑点会删除
      // 通过鼠标在折线上删除一个节点或在多边形上删除一个顶点时触发此事件
      editor.on('removenode', this.onEditRemove);
      // 在调用close方法时，触发该事件，target即为编辑后的折线/多边形对象
      editor.on('end', this.onEditEnd);

      editIsOpen && this.openEditor();

    },
    clearEditor() {
      this.closeEditor();
      if (this.polyEditor) {
        const map = this.getMap();
        // NOTE: 未验证是否有用
        map && map.removeControl(this.polyEditor);
        this.polyEditor = null;
      }
    },


    onClick(e) {
      const { path } = this;
      this.$emit('click', path, e);
    },
    onHide(e) {
      const { path } = this;
      this.$emit('hide', path, e);
    },
    onShow(e) {
      const { path } = this;
      this.$emit('show', path, e);
    },

    onEditAdd(e) {
      this.$emit('editAdd', e);
    },
    onEditAdjust(e) {
      this.$emit('editAdjust', e);
    },
    onEditRemove(e) {
      this.$emit('editRemove', e);
    },
    onEditEnd(e) {
      this.$emit('editEnd', e);
    },

    getMap() {
      return this._getMapRootMap?.();
    },
  }
}
</script>