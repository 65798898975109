<template></template>

<script>
import { isNil, throttle } from 'lodash';
import { sleepIf } from '@/utils/sleep';
import { loadPlugin } from './utils/loadPlugin';

export default {
  name: 'ARectangle',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
  ],
  emits: [
    'click',
    'show',
    'hide',
    'editAdjust',
    'editEnd'
  ],
  model: {
    prop: 'isVisible',
  },
  props: {
    // 是否显示
    isVisible: {
      type: Boolean,
      default: true,
    },
    /**
     * PolygonOptions
     */
    ctorOpts: {
      type: Object,
      default: null,
    },
    /**
     * 是否可编辑
     */
    editable: {
      type: Boolean,
      default: false,
    },
    /**
     * 开启编辑状态
     */
    editIsOpen: {
      type: Boolean,
      default: false,
    },
    /**
      * 自动缩放地图到合适的视野级别
      */
    autoFitView: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否自动偏移到中心, 与`autoFitView`互斥, 比`autoFitView`的优先级低
     */
    autoPanTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 在视野内是否平移
     */
    panToInView: {
      type: Boolean,
      default: false,
    },
    /**
     * 矩形的范围
     */
    bounds: {
      // LngLat[]
      type: Array,
      default: null,
      required: true,
    },
    /**
     * 线条颜色，使用16进制颜色代码赋值
     */
    strokeColor: {
      type: String,
      default: '#FF33FF', // '#006600'
    },
    /**
     * 线条宽度，单位：像素
     */
    strokeWeight: {
      type: Number,
      default: 6,
    },
    /**
     * 线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
     */
    strokeOpacity: {
      type: Number,
      default: 0.2,
    },
    /**
     * 线样式，实线:solid，虚线:dashed
     */
    strokeStyle: {
      type: String,
      default: 'solid',
    },
    /**
     * 多边形填充颜色，使用16进制颜色代码赋值
     */
    fillColor: {
      type: String,
      default: '#1791f'
    },
    /**
     * 多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。
     */
    fillOpacity: {
      type: Number,
      default: 0.4,
    }

  },
  data() {
    // 覆盖物 矩形实例
    this.mapOverlay = null;
    // 折线、多边形编辑插件 实例
    this.mapEditor = null;
    return {};
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.show();
      } else {
        this.hide();
      }
    },
    async editable(val) {
      const { mapEditor } = this;
      if (val) {
        mapEditor || this.initEditor();
      }
    },
    async editIsOpen(isOpen) {
      const { editable } = this;
      if (isOpen) {
        if (editable) {
          // 等图形画好
          await this.$nextTick();
          this.openEditor();
        }
      } else {
        this.closeEditor();
      }
    },
    bounds(val) {
      this.setBounds(val);
    },
  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEditAdjust = this.onEditAdjust.bind(this);
    this.onEditEnd = this.onEditEnd.bind(this);

    this.fitViewOrPanTo = throttle(this.fitViewOrPanTo, 500).bind(this);

  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { mapOverlay, mapEditor } = this;

    if (mapEditor) {
      mapEditor.off('adjust', this.onEditAdjust);
      mapEditor.off('end', this.onEditEnd);
    }

    if (mapOverlay) {
      mapOverlay.off('click', this.onClick);
      mapOverlay.off('hide', this.onHide);
      mapOverlay.off('show', this.onShow);
    }
    this.clearEditor();
    this.clear();
  },
  methods: {

    async init() {
      const { editable } = this;
      await this.initRectangle();

      if (editable) {
        await this.initEditor();
      }
    },

    fitViewOrPanTo() {
      const { autoFitView, autoPanTo, panToInView } = this;
      if (autoFitView) {
        this.setFitView();
      } else if (autoPanTo) {
        // 平移到中心点
        this.panToCenter(panToInView);
      }
    },
    /**
     * 地图中心点平移至矩形的中心点
     * `inView`: false, 在视野外才会平移
     */
    panToCenter(inView = false) {
      const map = this.getMap();
      if (!map) return;
      // 获取矩形的中心点
      const center = this.getBounds()?.getCenter();
      const bounds = map.getBounds(); // 判断是否在地图视野内
      if (inView || !bounds.contains(center)) {
        map.panTo(center);
      }
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      const map = this.getMap();
      map?.setFitView(mapOverlay);
    },

    show() {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      mapOverlay.show();
      this.fitViewOrPanTo();
    },
    hide() {
      const { mapOverlay } = this;
      mapOverlay?.hide();
    },

    /**
     * 设置矩形的范围
     * @param {LngLat[]} path
     */
    setBounds(lngLats) {
      const { mapOverlay } = this;
      if (!mapOverlay) return;
      if (lngLats.length < 2) return;
      mapOverlay.setBounds(this.toBounds(lngLats));
      this.fitViewOrPanTo();
    },
    /**
     * 获取矩形范围
     * https://lbs.amap.com/api/javascript-api/reference/core#Bounds
     */
    getBounds() {
      const { mapOverlay } = this;
      return mapOverlay?.getBounds();
    },
    /**
     * 判断指定点坐标是否在矩形内
     */
    contains(lngLat) {
      const { mapOverlay } = this;
      return mapOverlay?.contains(lngLat);
    },

    async initRectangle() {
      await sleepIf(8000, () => !isNil(this.getMap()));

      const {
        bounds,
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        ctorOpts,
        isVisible
      } = this;
      const map = this.getMap();

      // https://lbs.amap.com/api/javascript-api/reference/overlay#rectangle
      const rectangle = new AMap.Rectangle({
        strokeWeight,
        strokeColor,
        strokeOpacity,
        strokeStyle,
        fillColor,
        fillOpacity,
        map,

        ...(ctorOpts || {})
      });
      this.mapOverlay = rectangle;

      this.setBounds(bounds);

      if (!isVisible) {
        this.hide();
      }

      rectangle.on('click', this.onClick);
      rectangle.on('hide', this.onHide);
      rectangle.on('show', this.onShow);

    },
    clear() {
      this.hide();
      const { mapOverlay, mapEditor } = this;

      if (mapEditor) {
        mapEditor.close();
      }


      if (mapOverlay) {
        // 参数为null时，在地图上移除当前折线
        mapOverlay.setMap(null);
      }
      this.mapEditor = null;
      this.mapOverlay = null;
    },

    openEditor() {
      const { mapEditor } = this;
      mapEditor?.open();
    },
    closeEditor() {
      const { mapEditor } = this;
      mapEditor?.close();
    },

    async initEditor() {


      await sleepIf(8000, () => !isNil(this.getMap()));
      const map = this.getMap();
      const { mapOverlay, editIsOpen } = this;

      // 等待插件加载完成
      await loadPlugin(['AMap.RectangleEditor']);

      const editor = new AMap.RectangleEditor(map, mapOverlay);
      this.mapEditor = editor;

      // 鼠标调整折线上某个节点或多边形上某个顶点的位置时触发此事件
      editor.on('adjust', this.onEditAdjust);
      // 在调用close方法时，触发该事件，target即为编辑后的折线/多边形对象
      editor.on('end', this.onEditEnd);

      editIsOpen && this.openEditor();

    },
    clearEditor() {
      this.closeEditor();
      if (this.mapEditor) {
        const map = this.getMap();
        // NOTE: 未验证是否有用
        map && map.removeControl(this.mapEditor);
        this.mapEditor = null;
      }
    },


    onClick(e) {
      const { path } = this;
      this.$emit('click', path, e);
    },
    onHide(e) {
      const { path } = this;
      this.$emit('hide', path, e);
    },
    onShow(e) {
      const { path } = this;
      this.$emit('show', path, e);
    },


    onEditAdjust(e) {
      const { path } = this;
      this.$emit('editAdjust', path, e);
    },
    onEditEnd(e) {
      this.$emit('editEnd', e);
    },

    /**
     * 将 `[[southWest],[northEast]]` 转为 `Bounds`类型
     * southWest: 西南角经纬度 [lng, lat]
     * northEast: 东北角经纬度 [lng, lat]
     */
    toBounds(lngLats) {
      const [] = lngLats;

      const southWest = new AMap.LngLat(...lngLats[0]);
      const northEast = new AMap.LngLat(...lngLats[1]);

      return new AMap.Bounds(southWest, northEast);
    },
    /**
     * 将 `Bounds`类型 转为 数组
     */
    toLngLats(bounds) {
      // 取西南角坐标。
      const southWest = bounds.getSouthWest();
      // 获取东北角坐标
      const northEast = bounds.getNorthEast();

      return [
        [southWest.getLng(), southWest.getLat()],
        [northEast.getLng(), northEast.getLat()]
      ];
    },


    getMap() {
      return this._getMapRootMap?.();
    },

  }
}
</script>