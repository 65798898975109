/**
 * 封装高德地图组件
 */
import BSJMap from './Map.vue';
import InfoWindow from './InfoWindow.vue';
import MapMarker from './MapMarker.vue';
import MapPolyline from './MapPolyline.vue';
import MapPolygon from './MapPolygon.vue';
import MapRectangle from './MapRectangle.vue';
import MapCircle from './MapCircle.vue';
import MapTrack from './MapTrack.vue';
import MapClusterer from './MapClusterer.vue';

export default BSJMap;

export {
  BSJMap,
  // 信息窗体
  InfoWindow,
  // 点标记
  MapMarker,
  // 折线
  MapPolyline,
  // 多边形
  MapPolygon,
  // 矩形
  MapRectangle,
  // 圆形
  MapCircle,
  // 轨迹回放
  MapTrack,
  // 聚合点
  MapClusterer,
};