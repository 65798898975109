<template>
  <div v-show="false">
    <!-- 自定义点标记图标 -->
    <div :lnglat="lngLat" v-if="$slots.default" ref="contentRef">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import { isNil, throttle } from 'lodash';
import { sleepIf } from '@/utils/sleep';

// 默认图标
const defaultIcon = require('@/assets/images/默认点标记.png');
// 默认偏移量
const defaultOffset = [0, 0];

export default {
  name: 'AMarker',
  inject: [
    // function 从父组件获取地图实例
    '_getMapRootMap',
  ],
  emits: [
    'click',
    'moving',
    'moveend',
    'movealong',
  ],
  model: {
    prop: 'isVisible',
  },
  props: {
    // 是否显示
    isVisible: {
      type: Boolean,
      default: true,
    },
    // 不指定目标显示地图
    noMap: {
      type: Boolean,
      default: false,
    },

    /**
     * MarkerOptions
     * https://lbs.amap.com/api/javascript-api/reference/overlay#marker
     */
    ctorOpts: {
      type: Object,
      default: null,
    },
    lngLat: {
      type: Array,
      default: null,
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    autoFitView: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否自动偏移到`lngLat`, 与`autoFitView`互斥, 比`autoFitView`的优先级低
     */
    autoPanTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 在视野内是否平移
     */
    panToInView: {
      type: Boolean,
      default: false,
    },
    // 需在点标记中显示的图标
    // 有合法的content内容时，此属性无效
    icon: {
      type: String,
      default: '',
    },
    // 点标记的旋转角度，广泛用于改变车辆行驶方向
    // 注：angle属性是使用CSS3来实现的，支持IE9及以上版本
    angle: {
      type: Number,
      default: 0,
    },
    // 点标记显示位置偏移量，默认值为Pixel(-10,-34)
    offset: {
      // Pixel
      type: Array,
      default: null,
    },
    /**
     * 鼠标滑过点标记时的文字提示，不设置则鼠标滑过点标无文字提示
     */
    title: {
      type: String,
      default: '',
    },
    /**
    是否自动旋转。
    点标记在使用moveAlong动画时，
    路径方向若有变化， 点标记是否自动调整角度，默认为false。
    广泛用于自动调节车辆行驶方向。
    IE8以下不支持旋转，autoRotation属性无效
     */
    autoRotation: {
      type: Boolean,
      default: false,
    },
    isTop: {
      type: Boolean,
      default: false,
    },
    /**
     * 文本标注的内容
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * 文本标注偏移量（默认基准点为图标左上角）
     *  new AMap.Pixel(...labelOffset)
     */
    labelOffset: {
      type: Array,
      default() {
        return [0, 0];
      }
    },
    /**
     * 为文本标注方位
     */
    labelDirection: {
      type: String,
      default: 'top',
      validator(val) {
        return [
          'top',
          'right',
          'bottom',
          'left',
          'center'
        ].includes(val);
      }
    }
  },
  data() {
    // 点标记实例
    this.mapMarker = null;
    return {
    };
  },
  watch: {
    // 控制打开关闭窗体
    isVisible(visible) {
      const { lngLat } = this;
      if (visible) {
        this.open(lngLat);
      } else {
        this.close();
      }
    },

    // 改变信息窗体位置
    lngLat(val) {
      const { isVisible } = this;
      if (isVisible) {
        this.setPosition(val);
      } else {
        this.open(val);
      }
    },

    // 改变图标
    icon(val) {
      this.setIcon(val);
    },
    // 改变方向
    angle(val) {
      this.setAngle(val);
    },
    offset(val) {
      this.setOffset(val);
    },
    isTop(val) {
      this.setTop(val);
    },
    title(val) {
      this.setTitle(val);
    },
    label(val) {
      const { mapMarker } = this;
      if (!mapMarker) return;
      mapMarker.setLabel({
        content: val,
      });
    },
    labelOffset(val) {
      const { mapMarker } = this;
      if (!mapMarker) return;
      mapMarker.setLabel({
        offset: new AMap.Pixel(...val)
      });
    },
    labelDirection(val) {
      const { mapMarker } = this;
      if (!mapMarker) return;
      mapMarker.setLabel({
        direction: val,
      });
    }
  },
  beforeMount() {
    this.onClick = this.onClick.bind(this);
    this.onMoving = this.onMoving.bind(this);
    this.onMoveend = this.onMoveend.bind(this);
    this.onMovealong = this.onMovealong.bind(this);

    this.fitViewOrPanTo = throttle(this.fitViewOrPanTo, 500).bind(this);

  },
  mounted() {
    this.init();
  },
  beforeDestroy() {

    const { mapMarker } = this;
    if (mapMarker) {
      mapMarker.off('click', this.onClick);
      mapMarker.off('moving', this.onMoving);
      mapMarker.off('moveend', this.onMoveend);
      mapMarker.off('movealong', this.onMovealong);
    }

    this.clear();
  },
  methods: {

    async init() {
      await this.initMarker();
      const { isVisible } = this;
      if (isVisible) {
        this.open(this.lngLat);
      }
    },
    fitViewOrPanTo() {
      const { autoFitView, autoPanTo, panToInView } = this;
      if (autoFitView) {
        this.setFitView();
      } else if (autoPanTo) {
        this.panToPoint(panToInView);
      }
    },
    /**
     * 地图中心点平移至点标记
     */
    panToPoint(inView = false) {
      const map = this.getMap();
      if (!map) return;
      const { lngLat } = this;
      if (!lngLat) return;
      const bounds = map.getBounds();
      if (inView || !bounds.contains(lngLat)) {
        map.panTo(lngLat);
      }
    },
    /**
     * 自动缩放地图到合适的视野级别
     */
    setFitView() {
      const { mapMarker } = this;
      if (!mapMarker) return;
      const map = this.getMap();
      map?.setFitView(mapMarker);
    },

    setPosition(lngLat) {
      const { mapMarker } = this;
      if (!mapMarker) return;
      mapMarker.setPosition([...lngLat]);
      this.fitViewOrPanTo();
    },
    open(lngLat) {
      const { mapMarker } = this;
      if (!mapMarker) return;
      this.setPosition(lngLat);
      mapMarker.show();
      this.fitViewOrPanTo();
    },
    close() {
      const { mapMarker } = this;
      mapMarker?.hide();
    },
    setIcon(icon) {
      const { mapMarker } = this;

      if (this.$slots.default) return;

      mapMarker?.setIcon(icon || defaultIcon);
    },
    setContent(html) {
      const { mapMarker } = this;
      mapMarker?.setContent(html);
    },
    setAngle(angle) {
      const { mapMarker } = this;
      mapMarker?.setAngle(angle);
    },
    getOffset() {
      const { mapMarker } = this;
      return mapMarker?.getOffset();
    },
    // 置Marker偏移量, Pixel
    setOffset(offset) {
      const { mapMarker } = this;
      mapMarker?.setOffset(new AMap.Pixel(...(offset || defaultOffset)));
    },
    // 地图上有多个marker时，当isTop为true时，marker将显示在最前面；
    // 当为false时，marker取消置顶
    setTop(isTop = false) {
      const { mapMarker } = this;
      mapMarker?.setTop(isTop);
    },
    /**
     * 鼠标滑过点标时的文字提示
     */
    setTitle(title = '') {
      const { mapMarker } = this;
      mapMarker?.setTitle(title);
    },
    /**
     * 以指定的速度，点标记沿指定的路径移动
     * @param {LngLat[]} path - 轨迹路径的经纬度对象的数组
     * @param {number} speed - speed为指定速度，单位：千米/小时，不可为0
     */
    moveAlong(path = [], speed = 200) {
      const { mapMarker } = this;
      const clonePath = (path || []).map(point => [...point]);
      mapMarker?.moveAlong(clonePath, speed);
    },
    /**
     * 以给定速度移动点标记到指定位置
     * @param {LngLat} lngLat - 参数lnglat为指定位置，必设
     * @param {number} speed - 为指定速度，单位：千米/小时，不可为0
     */
    moveTo(lngLat, speed) {
      const { mapMarker } = this;

      mapMarker?.moveTo(new AMap.LngLat(...lngLat), speed);
    },
    /**
     * 点标记停止动画
     */
    stopMove() {
      const { mapMarker } = this;
      mapMarker?.stopMove();
    },
    /**
     * 暂定点标记的动画效果
     */
    pauseMove() {
      const { mapMarker } = this;
      mapMarker?.pauseMove();
    },
    /**
     * 重新开始点标记的动画效果
     */
    resumeMove() {
      const { mapMarker } = this;
      mapMarker?.resumeMove();
    },


    async initMarker() {
      await sleepIf(8000, () => !isNil(this.getMap()));

      const {
        noMap,
        ctorOpts,
        angle,
        icon,
        offset,
        autoRotation,
        isTop,
        title,
        label,
        labelOffset,
        labelDirection,
      } = this;
      const { contentRef } = this.$refs;
      const map = this.getMap();

      // https://lbs.amap.com/api/javascript-api/reference/overlay#marker
      const marker = new AMap.Marker({
        // 鼠标点击时marker是否置顶
        topWhenClick: true,
        // 点标记是否可拖拽移动
        draggable: false,
        // 点标记是否可见
        visible: false,
        // 设置点标记锚点
        anchor: 'center',
        angle: angle,
        autoRotation: autoRotation,
        ...(ctorOpts | {})
      });
      this.mapMarker = marker;

      if (!noMap) {
        marker.setMap(map);
      }

      if (offset) {
        this.setOffset(offset);
      }

      if (isTop) {
        this.setTop(isTop);
      }
      if (title) {
        this.setTitle(title);
      }

      if (this.$slots.default) {
        this.setContent(contentRef);
      } else {
        this.setIcon(icon);
      }

      if (label) {
        marker.setLabel({
          offset: new AMap.Pixel(...labelOffset),
          direction: labelDirection,
          content: label,
        });
      }

      marker.on('click', this.onClick);
      // 点标记在执行moveTo，moveAlong动画时触发事件，
      // Object对象的格式是{passedPath:Array.<LngLat>}。
      // 其中passedPath为Marker对象在moveAlong或者moveTo过程中已经走过的路径。
      marker.on('moving', this.onMoving);
      // 点标记执行moveTo动画结束时触发事件，也可以由moveAlong方法触发
      marker.on('moveend', this.onMoveend);
      // 点标记执行moveAlong动画一次后触发事件
      marker.on('movealong', this.onMovealong);

    },


    clear() {
      this.close();
      const { mapMarker } = this;
      if (mapMarker) {
        mapMarker.setMap(null);
      }
      this.mapMarker = null;
    },

    getMarker() {
      return this.mapMarker;
    },


    onClick(e) {
      const { lngLat } = this;
      this.$emit('click', lngLat, e);
    },
    onMoving(e) {
      // 点标记在执行moveTo，moveAlong动画时触发事件，
      // Object对象的格式是{ passedPath: Array.<LngLat>}。
      // 其中 passedPath 为Marker对象在moveAlong或者moveTo过程中已经走过的路径。

      this.$emit('moving', e);
    },
    onMoveend(e) {
      this.$emit('moveend', e);
    },
    onMovealong(e) {
      this.$emit('movealong', e);
    },


    getMap() {
      return this._getMapRootMap?.();
    },
  }
}
</script>
<style scoped>
.carMarker {
   margin-top: 32px;
}
</style>